<template>
    <div>
        <div class="main">
            <div class="search">
                <div class="refresh" @click="search"><i class="fa fa-refresh"></i></div>
                <el-select v-model="message.byField" size="mini"
                           placeholder="검색조건"
                           style="width:120px;;margin-left: 10px">
                    <el-option label="전체" :value="null">전체</el-option>
                    <el-option label="회원아이디" :value="managerConst.User.SEARCH_BY_USERNAME">회원아이디</el-option>
                    <el-option label="회원닉네임" :value="managerConst.User.SEARCH_BY_NICKNAME">회원닉네임
                    </el-option>
                </el-select>
                <el-input type="text" size="mini" v-model="message.searchString" placeholder="아이디/닉네임"
                          style="width: 150px;margin-left: 5px"></el-input>
                <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색
                </el-button>
                <el-select v-model="message.checked" @change="getMessageList" size="mini"
                           placeholder="쪽지상태"
                           style="width:120px;margin-left: 5px">
                    <el-option label="전체" :value="null">전체</el-option>
                    <el-option label="미확인" :value="managerConst.UNCHECKED">미확인</el-option>
                    <el-option label="확인완료" :value="managerConst.CHECKED">읽음</el-option>
                </el-select>
                <el-select v-model="message.type" @change="getMessageList" size="mini"
                           placeholder="쪽지분류"
                           style="width:120px;margin-left: 5px">
                    <el-option label="개인별쪽지" :value="managerConst.Message.TYPE_PRIVATE">개인별쪽지</el-option>
                    <el-option label="전체쪽지" :value="managerConst.Message.TYPE_PUBLIC">전체쪽지</el-option>
                </el-select>
                <el-button size="mini" type="primary" style="margin-left: 10px" @click="showMessagePanel"><i
                        class="fa fa-pencil">쪽지보내기</i></el-button>
            </div>

            <div class="data" style="overflow-y: scroll;max-height: 750px">
                <div class="customer_panel" v-for="item in messageList">
                    <div style="width: 10%; padding-left: 10px;">
                        <el-button size="mini" type="success"
                                   v-if="item.user&&item.type == managerConst.Message.TYPE_PRIVATE"
                                   @click="editUser(item.userId,managerConst.MODE_EDIT)">
                            <i class="fa fa-user-circle"> {{item.user.nickname}}</i>
                        </el-button>
                        <span v-if="item.type == managerConst.Message.TYPE_PUBLIC">전체쪽지</span>
                    </div>
                    <div style="width: 10%">
                        {{item.createTime|datef('yyyy-MM-DD HH:mm')}}
                    </div>
                    <div style="width: 10%">
                        <span v-if="item.checked == managerConst.CHECKED">읽음</span>
                        <span v-if="item.checked == managerConst.UNCHECKED" style="color: red">미확인</span>
                    </div>
                    <div style="width: 10%">
                        <el-button size="mini" type="danger"  @click="deleteMessage(item.id)">삭제</el-button>
                    </div>
                    <div style="width: 100%;cursor: pointer" class="direct-chat-text" v-html="item.content"></div>
                </div>

            </div>
            <div class="pagePanel">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[20,50, 100, 150, 200]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageTotal">
                </el-pagination>
            </div>


        </div>
        <el-dialog title="쪽지보내기" :visible.sync="dialogSendMessageVisible" style="width: 100%">
            <div style="display: flex;justify-content: space-between;align-items: flex-start;flex-wrap:wrap">
                <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center;padding: 10px 10px">
                    <el-select size="mini" v-model="messageTemp.message2who" placeholder="레벨 선택">
                        <el-option :value="managerConst.Message.MESSAGE2WHO_ALLUSER" label="전체회원">전체회원</el-option>
                        <el-option :value="managerConst.Message.MESSAGE2WHO_USER" label="회원별">회원별</el-option>
                        <el-option :value="managerConst.Message.MESSAGE2WHO_RANK" label="레벨회원">레벨회원</el-option>
                        <el-option :value="managerConst.Message.MESSAGE2WHO_GENERALAGENT" label="부본사회원">부본사회원
                        </el-option>
                        <el-option :value="managerConst.Message.MESSAGE2WHO_AGENT" label="총판회원">총판회원</el-option>
                        <el-option :value="managerConst.Message.MESSAGE2WHO_BANKTYPE" label="계좌별회원">계좌별회원
                        </el-option>
                    </el-select>
                    <!--레벨-->
                    <el-select size="mini" style="margin-left: 10px"
                               v-if="messageTemp.message2who == managerConst.Message.MESSAGE2WHO_RANK"
                               v-model="messageTemp.rankId" placeholder="레벨 선택">
                        <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">
                            {{rank.rankName}}
                        </el-option>
                    </el-select>
                    <el-input size="mini" style="margin-left: 10px;width: 800px"
                              v-if="messageTemp.message2who == managerConst.Message.MESSAGE2WHO_USER"
                              v-model="messageTemp.usernicknames"></el-input>
                    <el-select size="mini" style="margin-left: 10px"
                               v-if="messageTemp.message2who == managerConst.Message.MESSAGE2WHO_GENERALAGENT"
                               v-model="messageTemp.partnerId" placeholder="부본사회원">
                        <el-option v-for="item in generalAgentList" :value="item.id" :label="item.nickname">
                            {{item.nickname}}
                        </el-option>
                    </el-select>
                    <el-select size="mini" style="margin-left: 10px"
                               v-if="messageTemp.message2who == managerConst.Message.MESSAGE2WHO_AGENT"
                               v-model="messageTemp.partnerId" placeholder="총판회원">
                        <el-option v-for="item in agentList" :value="item.id" :label="item.nickname">
                            {{item.nickname}}
                        </el-option>
                    </el-select>
                    <el-select size="mini" style="margin-left: 10px"
                               v-if="messageTemp.message2who == managerConst.Message.MESSAGE2WHO_BANKTYPE"
                               v-model="messageTemp.bankType" placeholder="계좌별회원">
                        <el-option :value="managerConst.User.BANK_TYPE_NORMAL" label="수동">수동</el-option>
                        <el-option :value="managerConst.User.BANK_TYPE_BANKCARDID" label="실계좌"> 실계좌</el-option>
                        <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD" label="가상계좌 1"> 가상계좌 1</el-option>
                        <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_2" label="가상계좌 2"> 가상계좌 2
                        </el-option>
                        <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_3" label="가상계좌 3"> 가상계좌 3
                        </el-option>
                        <el-option :value="managerConst.User.BANK_TYPE_FACK_CARD_4" label="가상계좌 4"> 가상계좌 4
                        </el-option>
                    </el-select>
                </div>

                <div style="width: 50%">
                    <div class="editor" style="padding: 5px">
                        <quill-editor ref="myQuillEditor" v-model="content"
                                      :options="editorOption"
                                      @ready="onEditorReady($event)"
                                      @change="onEditorChange($event)"/>
                    </div>
                </div>
                <div style="width: 50%;display: flex;justify-content: flex-start;align-items: flex-start;flex-wrap: wrap">
                    <el-button type="success" size="mini" style="width: 30%;margin: 5px"
                               v-for="tmp in messageTempList" @click="setMessageContentByTemplate(tmp)">
                        {{tmp.title}}
                    </el-button>
                </div>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sendMessage">쪽지보내기</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getRegisterCodeList, updateRegisterCode} from "../../network/manager/registerCodeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList, updateGroup} from "../../network/manager/groupRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {displayNoneAll, getCustomerCetnerList} from "../../network/manager/customercenterRequest";
    import CustomerReplayToast from "../../components/administrator/UserPanel/CustomerReplayToast";
    import {deleteMessage, getMessageList, saveMessage} from "../../network/manager/messageRequest";
    import {getCusTempList} from "../../network/manager/customerTemplateRequest";
    import {getPartnerList} from "../../network/manager/partnerRequest";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$customerReplayEditToast = CustomerReplayToast

    export default {
        name: "ManagerMessage",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                message: {},
                messageList: [],
                messageTemp: {message2who: managerConst.Message.MESSAGE2WHO_ALLUSER, content: ''},
                content: '',
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                dialogSendMessageVisible: false,
                rankList: [],
                groupList: [],
                messageTempList: [],
                generalAgentList: [],
                agentList: [],
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],         //引用，代码块
                                [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                                [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
                                [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                                [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                                [{'direction': 'rtl'}],                         // 文本方向
                                [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
                                [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
                                [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                                [{'font': []}],         //字体
                                [{'align': []}],        //对齐方式
                                ['clean'],        //清除字体样式
                            ]
                        }
                    },

                },

            }
        },
        methods: {
            sendMessage() {
                this.messageTemp.content = this.content
                console.log(this.messageTemp)
                saveMessage(this.messageTemp).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '쪽지발송이 완료되였습니다'
                        });
                        this.dialogSendMessageVisible = false
                        this.content = ''
                        this.messageTemp.message2who = managerConst.Message.MESSAGE2WHO_ALLUSER
                        this.getMessageList()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 2000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }

                })
            },
            setMessageContentByTemplate(temp) {
                this.content = temp.reply
            },
            showMessagePanel() {
                this.dialogSendMessageVisible = true
                getPartnerList({'generalAgentId': 0}, 1, 1000).then(res => {
                    let list = res.data.data;
                    list.map(partner => {
                        this.generalAgentList.push(partner)
                        partner.agentList.map(p => {
                            this.agentList.push(p)
                        })
                    });
                })
                getRankList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                    this.rankList = res.data.data
                })
                getGroupList({'status': managerConst.ENABLE}, 1, 100).then(res => {
                    this.groupList = res.data.data
                })
                getCusTempList({type: managerConst.CustomercenterTemplateType.TYPE_NORMAL}, 1, 100).then(res => {
                    this.messageTempList = res.data.data
                })
            },
            search() {
                this.message.checked = null
                this.getMessageList()
            },
            getMessageList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (this.message.type == managerConst.Message.TYPE_PUBLIC) {
                    this.message.text4public = managerConst.YES
                } else {
                    this.message.text4public = managerConst.NOT
                }
                this.message.startDate = this.startDate
                this.message.endDate = this.endDate
                getMessageList(this.message, this.pageNum, this.pageSize).then(res => {
                    this.messageList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            deleteMessage(id){
                this.$confirm('쪽지를 삭제하시겠습니까?', '쪽지 삭제하기', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deleteMessage(id).then(res=>{

                        if (res.data.success) {
                            this.getMessageList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }


                    })

                });
            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            editPartner(partnerId, mode) {
                this.$partnerEditToast({'partnerId': partnerId, 'mode': mode})
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCustomerList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCustomerList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            onEditorChange(editor) {
                this.messageTemp.content = editor.html;
            },
            onEditorReady(Quill) {

            },
        },
        created() {
            this.startDate = this.$moment().subtract(7, 'days').format('yyyy-MM-DD');
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.message.text4public = managerConst.NOT
            this.message.type = managerConst.Message.TYPE_PRIVATE
            this.getMessageList()
            this.$bus.$on('sendMessageComplate', () => {
                console.log('sendMessageComplate')
                this.getMessageList()
            })
        },
    }
</script>

<style scoped>

</style>